import "core-js/modules/es.array.push.js";
import { school_my_active_page, sendSchoolActive } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        active_name: '',
        current: 1,
        is_fabu: 1,
        total: 0
      },
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.active_name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      school_my_active_page(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(id) {
      this.$root.useRouter.push({
        path: "/school/schoolActive/myActiveInfo",
        query: {
          id: id
        }
      });
    },
    showSchool(id) {
      this.$root.useRouter.push({
        path: "/school/schoolActiveData/schoolDataInfo",
        query: {
          school_active_id: id
        }
      });
    },
    showGrade(id) {
      this.$root.useRouter.push({
        path: "/school/schoolActiveData/gradeAndTeamDataList",
        query: {
          school_active_id: id
        }
      });
    },
    showStudent(id) {
      this.$root.useRouter.push({
        path: "/school/schoolActiveData/studentReportDataList",
        query: {
          school_active_id: id
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    sendActive(id) {
      this.$root.ElMessageBox.confirm('活动发布后信息将下发至教师学生,你确定要现在操作吗?', '提示', {
        confirmButtonText: '继续操作',
        cancelButtonText: '我再想想',
        type: 'warning',
        draggable: true
      }).then(() => {
        sendSchoolActive({
          school_active_id: id
        }).then(res => {
          if (res.success) {
            this.$root.ElMessage({
              type: 'success',
              message: res.msg
            });
            this.search();
          } else {
            this.$root.ElMessage.error(res.msg);
          }
        });
      }).catch(() => {});
    }
  }
};